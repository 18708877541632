/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LayoutEnum,
    LayoutEnumFromJSON,
    LayoutEnumFromJSONTyped,
    LayoutEnumToJSON,
} from './LayoutEnum';
import {
    LookupKeyEnum,
    LookupKeyEnumFromJSON,
    LookupKeyEnumFromJSONTyped,
    LookupKeyEnumToJSON,
} from './LookupKeyEnum';
import {
    SubscriptionEnum,
    SubscriptionEnumFromJSON,
    SubscriptionEnumFromJSONTyped,
    SubscriptionEnumToJSON,
} from './SubscriptionEnum';
import {
    ThemeEnum,
    ThemeEnumFromJSON,
    ThemeEnumFromJSONTyped,
    ThemeEnumToJSON,
} from './ThemeEnum';
import {
    UserRoleEnum,
    UserRoleEnumFromJSON,
    UserRoleEnumFromJSONTyped,
    UserRoleEnumToJSON,
} from './UserRoleEnum';

/**
 * 
 * @export
 * @interface UserProfileDto
 */
export interface UserProfileDto {
    /**
     * 
     * @type {UserRoleEnum}
     * @memberof UserProfileDto
     */
    role: UserRoleEnum;
    /**
     * 
     * @type {ThemeEnum}
     * @memberof UserProfileDto
     */
    theme: ThemeEnum;
    /**
     * 
     * @type {LayoutEnum}
     * @memberof UserProfileDto
     */
    layout: LayoutEnum;
    /**
     * 
     * @type {SubscriptionEnum}
     * @memberof UserProfileDto
     */
    subscription: SubscriptionEnum;
    /**
     * 
     * @type {LookupKeyEnum}
     * @memberof UserProfileDto
     */
    interestedInSubscription: LookupKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    zendeskToken?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDto
     */
    isOnWaitingList: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    nickname: string;
    /**
     * 
     * @type {Date}
     * @memberof UserProfileDto
     */
    subscriptionEndDate: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDto
     */
    newsletter: boolean;
}

export function UserProfileDtoFromJSON(json: any): UserProfileDto {
    return UserProfileDtoFromJSONTyped(json, false);
}

export function UserProfileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': UserRoleEnumFromJSON(json['role']),
        'theme': ThemeEnumFromJSON(json['theme']),
        'layout': LayoutEnumFromJSON(json['layout']),
        'subscription': SubscriptionEnumFromJSON(json['subscription']),
        'interestedInSubscription': LookupKeyEnumFromJSON(json['interestedInSubscription']),
        'zendeskToken': !exists(json, 'zendeskToken') ? undefined : json['zendeskToken'],
        'id': json['id'],
        'email': json['email'],
        'isOnWaitingList': json['isOnWaitingList'],
        'fullName': json['fullName'],
        'nickname': json['nickname'],
        'subscriptionEndDate': (json['subscriptionEndDate'] === null ? null : new Date(json['subscriptionEndDate'])),
        'newsletter': json['newsletter'],
    };
}

export function UserProfileDtoToJSON(value?: UserProfileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': UserRoleEnumToJSON(value.role),
        'theme': ThemeEnumToJSON(value.theme),
        'layout': LayoutEnumToJSON(value.layout),
        'subscription': SubscriptionEnumToJSON(value.subscription),
        'interestedInSubscription': LookupKeyEnumToJSON(value.interestedInSubscription),
        'zendeskToken': value.zendeskToken,
        'id': value.id,
        'email': value.email,
        'isOnWaitingList': value.isOnWaitingList,
        'fullName': value.fullName,
        'nickname': value.nickname,
        'subscriptionEndDate': (value.subscriptionEndDate === null ? null : value.subscriptionEndDate.toISOString()),
        'newsletter': value.newsletter,
    };
}

