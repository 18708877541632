export const cookies: CookieConsent.CookieConsentConfig = {
    autoClearCookies: true, // default: false
    mode: 'opt-in', // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    guiOptions: {
        consentModal: {
            layout: 'cloud', // box/cloud/bar
            position: 'bottom center', // bottom/middle/top + left/right/center
            flipButtons: false, // enable to invert buttons
        },
        preferencesModal: {
            layout: 'box',
            position: 'right',
            equalWeightButtons: true,
            flipButtons: false,
        },
    },
    categories: {
        necessary: {
            readOnly: true,
        },
        analytics: {},
    },
    language: {
        default: 'cs',
        autoDetect: 'browser',
        translations: {
            cs: {
                consentModal: {
                    title: 'Používáme cookies',
                    description:
                        'Soubory cookie používáme k analýze údajů o našich návštěvnících, ke zlepšení našich webových stránek, zobrazení personalizovaného obsahu a k tomu, abychom vám poskytli skvělý zážitek z webu.',
                    acceptAllBtn: 'Přijmout všechny',
                    acceptNecessaryBtn: 'Odmítnout všechny',
                    showPreferencesBtn: 'Podrobné nastavení',
                },
                preferencesModal: {
                    title: 'Podrobné nastavení',
                    acceptAllBtn: 'Přijmout všechny',
                    acceptNecessaryBtn: 'Odmítnout všechny',
                    savePreferencesBtn: 'Uložit podrobné nastavení',
                    closeIconLabel: 'Zavřít nastavení',
                    serviceCounterLabel: 'Service|Services',
                    sections: [
                        {
                            title: 'Cookie Usage',
                            description:
                                'Soubory cookie používáme k analýze údajů o našich návštěvnících, ke zlepšení našich webových stránek, zobrazení personalizovaného obsahu a k tomu, abychom vám poskytli skvělý zážitek z webu.',
                        },
                        {
                            title: 'Technický provoz stránek',
                            linkedCategory: 'necessary',
                        },
                        {
                            title: 'Personalizovaná reklama a obsah, měření reklamy a obsahu, poznatky o okruzích publika a vývoj produktů',
                            linkedCategory: 'analytics',
                        },
                        {
                            title: 'Více informací',
                            description:
                                'V případě dotazů týkajících se cookies nás, prosím, <a class="cc-link" href="mailto:info@monery.io">kontaktujte</a>.',
                        },
                    ],
                },
            },
        },
    },
};
